import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const Groupcompany = ({location}) => {

  const data = useStaticQuery(graphql`
    query {
      infoware: file(relativePath: { eq: "infoWare_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return(
    <Layout location={location} title="グループ企業" >
      <SEO title="グループ企業" description="弊社のグループ企業をご紹介いたします。"/>

      <div className="page-head">
				<Container expand="lg">
					<h2>グループ企業</h2>
					<p>グループ企業をご紹介いたします。</p>
				</Container>
				<div className="bottom-border" />
			</div>

      <Container expand="lg">
        <h1 className="mb-5">常に最善のサービスを提供するために。</h1>
        <p className="mb-6">
        グローバルワイズが⽬指すソリューションは、あらゆる⾓度からお客様の経営環境をとらえ、<br className="d-lg-block d-none" />
        実現と経験により培われたノウハウと、それを具現化する技術をもってはじめて実現されます。<br className="d-lg-block d-none" />
        きめ細やかなサービスを追求するほど、求めるノウハウや技術も限りなく広がっていきます。<br className="d-lg-block d-none" />
        常にお客様に最善のサービスを提供する為に、グループ企業だけではなく、各々の分野で優れた実績を持ちビジネスのベクトルを共有できる企業とも連携しています。
        </p>

        <Img fluid={data.infoware.childImageSharp.fluid} className="d-inline-bock" alt="株式会社インフォウェア"/>
        <Row>
          <Col lg={6}>
            <h3>株式会社インフォウェア（Japan-Net）</h3>
            <p>〒450-0003<br/>愛知県名古屋市中村区名駅南⼆丁⽬14番19号<br/>住友⽣命名古屋ビル21階</p>
            <p><strong>TEL 052-581-2600（代表）<br/>FAX 052-533-3611</strong></p>
            <Table responsive className="table-borderless my-3">
              <tbody>
                <tr>
                  <th className="pl-0"><FontAwesomeIcon icon={faCircle} size="lg" /> 業務内容</th>
                  <td>ISP（インターネットサービスプロバイダー）<br/>IDC（インターネットデータセンター）</td>
                </tr>
                <tr>
                  <th className="pl-0 pt-0"><FontAwesomeIcon icon={faCircle} size="lg" /> WEBサイト</th>
                  <td className="pt-0"><a href="https://jn2.japan-net.ne.jp" target="_blank" rel="noopener noreferrer" >https://jn2.japan-net.ne.jp</a></td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6}>
            <iframe title="株式会社インフォウェア" width="100%" height="400" frameBorder="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.650179709609!2d136.88639235!3d35.16534415000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600376df7ae7f785%3A0xd86238c869359624!2z44CSNDUwLTAwMDMg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5p2R5Yy65ZCN6aeF5Y2X77yS5LiB55uu77yR77yU4oiS77yR77yZIOS9j-WPi-eUn-WRveWQjeWPpOWxi-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1444266911202"></iframe>
          </Col>
        </Row>
        <hr className="dots-hr my-5" />
      </Container>
    </Layout>
  )
}

export default Groupcompany